import $ from 'jquery'

const changeSubmitButtonState = (submitButton, length, limit) => {
  if (length - 1 > limit) {
    submitButton.prop('disabled', true)
    submitButton.addClass('disabled')
  } else {
    submitButton.prop('disabled', false)
    submitButton.removeClass('disabled')
  }
}

const setCounterValue = (counter, length, limit) => {
  const adjustedLength = length - 1
  if (adjustedLength > 0) {
    counter.text(`${adjustedLength} of ${limit}`)
  } else {
    counter.text(limit)
  }
}

const initializeTrixTextEditor = (form) => {
  form.dataset.initialized = true
  const limit = parseInt(form.dataset.contentmaxsize, 10)
  const counter = $('.counter', form)
  const elementEditor = $('trix-editor', form)[0]
  const submitButton = $(`button[form=${form.id}][type=submit]`)

  counter.text(limit)

  elementEditor.addEventListener('trix-change', () => {
    const newDocumentText = elementEditor.editor.getDocument().toString()
    const { length } = newDocumentText

    changeSubmitButtonState(submitButton, length, limit)
    setCounterValue(counter, length, limit)
  })
}

$(() => {
  const findTrixEditorsOnPage = () => {
    const editorForms = $('.js-trix-editor-form')

    if (editorForms.length > 0) {
      editorForms.each((_index, form) => {
        if (form.dataset.initialized === 'false') initializeTrixTextEditor(form)
      })
    }
  }

  findTrixEditorsOnPage()

  window.findTrixEditorsOnPage = findTrixEditorsOnPage
})
